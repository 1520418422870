





























































































































































// import SelectExpected from "@/components/questionnaire/components/SelectExpected.vue";
import Field from "@/components/questionnaire/Field.vue";
// import VuChips from "@/components/vu-components/VuChips.vue";
// import VuSelect from "@/components/vu-components/VuSelect.vue";
import Questionnaire from "@/includes/logic/Questionnaire";
import { InputSetups } from '@/mixins/input-setups'
import { BRAND_TAG } from '@/includes/constants'

import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { UseFields } from "piramis-base-components/src/components/Pi";
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import cyrillicToTranslit from "cyrillic-to-translit-js";
import { cloneDeep, snakeCase } from "lodash";
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider, extend } from "vee-validate";
// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
// import vSelect from "vue-select";

extend("onlyLatin", value => {
  return /^([a-zA-Z_\s]+)$/gm.test(value);
});

// eslint-disable-next-line
const availableFieldTypes = ["text", "textarea", "select", "number", "date", "time", "checkbox", "files"];

// Типы полей со структурой
// eslint-disable-next-line
// const filedTypesArr = [
//   {
//     type           : "text",
//     name           : "",
//     label          : "",
//     placeholder    : "",
//     description: "",
//     required       : false,
//   },
//   {
//     type    : "textarea",
//     name    : "",
//     label   : "",
//     required: false,
//   },
//   {
//     type       : "select",
//     name       : "",
//     placeholder: "",
//     label      : "",
//     multiply   : false,
//     required   : false,
//   },
//   {
//     type       : "number",
//     name       : "",
//     placeholder: "",
//     label      : "",
//     min        : null,
//     max        : null,
//     step       : 1,
//     required   : false,
//   },
//   {
//     type       : "date",
//     name       : "",
//     label      : "",
//     minimumView: "",
//     maximumView: "",
//     required   : false,
//   },
//   {
//     type       : "time",
//     name       : "",
//     label      : "",
//     minimumView: "",
//     maximumView: "",
//     required   : false,
//   },
//   {
//     type    : "checkbox",
//     name    : "",
//     label   : "",
//     expected: "",
//     required: false,
//   },
// ];

@Component({
  components: {
    Tags,
    // flatPickr,
    // VuChips,
    Field,
    ValidationProvider,
    // vSelect,
    // SelectExpected,
  },
  data() {
    return {
      snakeCase
    }
  }
})
export default class FormFieldItem extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {

  @Prop({ type: Object, required: true }) field!:any

  @Prop({ type: Boolean, default: false }) disabled!:boolean

  @Watch('field.type')
  onFieldTypeChange(type:any):void {
    this.field.config = cloneDeep((Questionnaire as any).fieldTypesStructures[type]);
  }

  @Watch('field.config.multiple')
  onFieldConfigMultipleChange(multiple:boolean):void {
    if (multiple) {
      this.field.config.model = []
    }
  }

  fieldOptions: Array<SelectOptionData> = [
    {
      label: this.$t('form_item_field_type_text').toString(),
      value: 'text',
    },
    {
      label: this.$t('form_item_field_type_textarea').toString(),
      value: 'textarea',
    },
    {
      label: this.$t('form_item_field_type_select').toString(),
      value: 'select',
    },
    {
      label: this.$t('form_item_field_type_number').toString(),
      value: 'number',
    },
    {
      label: this.$t('form_item_field_type_date').toString(),
      value: 'date',
    },
    {
      label: this.$t('form_item_field_type_time').toString(),
      value: 'time',
    },
    {
      label: this.$t('form_item_field_type_checkbox').toString(),
      value: 'checkbox',
    },
    {
      label: this.$t('form_item_field_type_files').toString(),
      value: 'files',
      disabled: !this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense,
      tags: !this.$store.getters.isChatLicenseExists || !this.$store.getters.getHaveBrand || !this.$store.getters.getHaveLicense ? BRAND_TAG : []
    },
  ]

  availableFieldTypes = availableFieldTypes

  filesTypeOptions:Array<SelectOption> = [
    { label: this.$t('form_image_type'), value: "Image" },
    { label: this.$t('form_document_type'), value: "Document" },
    { label: this.$t('form_video_type'), value: "Video" },
    { label: this.$t('form_audio_type'), value: "Audio" }
  ]

  expectedFieldOptions:Array<SelectOption> = [
    { label: this.$t("form_item_expected_true"), value: true },
    { label: this.$t("form_item_expected_false"), value: false },
    { label: this.$t("form_item_expected_null"), value: null }
  ]

  onBlur(e:any) {
    this.nameModel = new cyrillicToTranslit({ preset: 'uk' })
      .transform(e.target.value.trim(), "_")
      .replace(/[^a-z0-9_]+/ig, "symbol")
  }

  get nameModel() {
    return this.field.config["name"];
  }

  set nameModel(val) {
    this.field.config["name"] = val;
  }
}
