















































































































































































import i18n from '@/i18n/i18n'
import { InputSetups } from '@/mixins/input-setups'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import MediaUploader from 'piramis-base-components/src/components/MediaUploader/MediaUploader.vue'
import FilesListRowScroll from 'piramis-base-components/src/components/File/FilesList/FilesListRowScroll.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { MediaFileData } from 'piramis-base-components/src/components/File/types'

import 'flatpickr/dist/flatpickr.css'
import { ValidationProvider, extend } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import Component from 'vue-class-component'
import { Mixins, Prop, Watch } from 'vue-property-decorator'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { Russian } from "flatpickr/dist/l10n/ru.js"
import English from "flatpickr/dist/l10n/default.js"
import { required } from "vee-validate/dist/rules";

extend('required', required)

extend('be_marked', (value: any) => {
  if (value) {
    return true
  }

  return i18n.t('validation_field_must_be_marked').toString()
})

extend('not_be_marked', (value: any) => {
  if (!value) {
    return true
  }

  return i18n.t('validation_field_must_not_be_marked').toString()
})

@Component({
  components: {
    flatPickr,
    ValidationProvider,
    vSelect,
    ConfigField,
    MediaUploader,
    FilesListRowScroll
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class Field extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop({ required: true, type: Object }) field!: any

  @Prop({ type: Number }) chatId!: number

  localAttachments: Array<any> = []

  @Watch('field.config.min')
  onFieldMinChange(): void {
    this.processField()
  }

  @Watch('localAttachments', { deep: true })
  onLocalAttachmentsChange(value: Array<any>): void {
    if (this.field.type === 'files') {
      this.field.config.model = this.clearImagesStruct(value)
    }
  }

  get selectOptions(): Array<SelectOption> {
    return this.field.config.values.map((v: string) => ({
      label: v,
      value: v
    }))
  }

  get getChatId() {
    return this.chatId ? this.chatId : this.$store.state.chatState.chat.chat_id
  }

  get rules() {
    if (this.field.config.required) {
      return 'required'
    }

    if (this.field.config.expected) {
      return 'be_marked'
    } else if (this.field.config.expected !== undefined && this.field.config.expected === false) {
      return 'not_be_marked'
    }

    return ''
  }

  get flatpickrLocale():any {
    if (this.$i18n.locale === 'ru') {
      return Russian
    }

    return English
  }

  mapToSelectOptions(values:Array<any>):Array<SelectOption> {
    return values.map(value => ({
      value: value,
      label: value
    }))
  }

  formFieldTarget():any {
    return { target: 'target', target_id: JSON.stringify({ type: 'group', id: this.$store.state.chatState.chat?.chat_id || this.chatId }) }
  }

  removeFile(index: number) {
    this.localAttachments.splice(index, 1)
  }

  clearImagesStruct(fullAttachments: Array<MediaFileData>) {
    return fullAttachments.map((attachment) => {
      return {
        'file': attachment.file,
        'type': attachment.type
      }
    })
  }

  filesDataChange(file: any) {
    if (this.localAttachments.length <= 10) {
      this.localAttachments.push(file)
    }
  }

  processField() {
    if (this.field.config && this.field.config.min) {
      this.field.config['model'] = this.field.config.min
    }
  }

  mounted() {
    this.processField()
  }
}
